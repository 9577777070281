<template>
  <div class="home">
    <div v-if="flg">
      path:{{$route.path}}<br />
      query:{{$route.query.code}}<br />
      loggedIn:{{loggedIn}}<br />
      user id : {{profile.userId}}<br />
      display name : {{profile.displayName}}<br />
      friendship : {{friendship.friendFlag}}<br />
      error : {{errMsg}}
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import liff from '@line/liff'
import axios from 'axios'
export default {
  name: 'Home',
  data(){
    return{
      loggedIn:false,
      profile:{},
      friendship:{},
      errMsg:'error',
      pushUrl:'',
      friendUrl:'',
      flg:false
    }
  },
  mounted() {
    // routerを使ってる時は、vueの宣言前にinitしないとクエリーが読み込めない
    // liff
    // .init({liffId:'1657003171-5r419113'})
    // .then(()=>{
    //   this.loggedIn = liff.isLoggedIn()
    //   this.getProfile()
    // })
    // .catch((err)=>{
    //   console.log(err)
    // })
    switch (this.$route.query.code.split('_')[0]){
      case 'shu':
        this.pushUrl = 'https://line.photoevent.jp/shu_uemura/photoline/push.php'
        this.friendUrl = 'https://line.me/R/ti/p/@gpb8229b'
        this.flg = false
        break
      case 'kaluna':
        this.pushUrl = 'https://line.photoevent.jp/shu_uemura/photoline/push.php'
        this.friendUrl = 'https://line.me/R/ti/p/@354qasdo'
        this.flg = true
        break
      default:
        this.pushUrl = 'https://line.photoevent.jp/shu_uemura/photoline/push.php'
        this.friendUrl = 'https://line.me/R/ti/p/@354qasdo'
        this.flg = false
        break
    }
    this.loggedIn = liff.isLoggedIn()
    this.getProfile()
  },
  methods:{
    getProfile(){
      liff.getProfile()
      .then((value)=>{
        this.profile=value
        this.getFriendship()
      })
    },
    getFriendship(){
      liff.getFriendship()
      .then((value)=>{
        this.friendship = value
        this.sendMessage()
      })
    },
    sendMessage(){
      //ログインしていたら
      if(this.loggedIn){
        //友達だったら
        if(this.friendship.friendFlag){
          let params = new URLSearchParams()
          params.append("userId", this.profile.userId)
          params.append("movieId", this.$route.query.code)
          axios.post(this.pushUrl,params)
          .then(response=>{
            if(response.data.result === 'complete'){
              liff.openWindow({url: this.friendUrl, external: false})
            }else{
              this.errMsg = 'IDがありません。'
            }
          })
          .catch(error => {
            // エラーを受け取る
            this.errMsg = error
          })
        }else{
          this.errMsg = 'お友達に追加してください。'
          liff.openWindow({url: this.friendUrl, external: false})
        }
      }else{
        this.errMsg = 'ログインしてください'
      }
    }
  }
}
</script>
