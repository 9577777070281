import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import liff from '@line/liff'
/*
let codePrefix = ''
// let liffId = ''

//参考URL:https://gray-code.com/javascript/get-parameter-of-url/
let url = new URL(window.location.href)
let params = url.searchParams
// forEachメソッド
params.forEach((value) => {
    if(0 <= value.indexOf('code=')){
        codePrefix = value.split('=')[1].split('_')[0]
    }
});
switch (codePrefix){
    case 'shu':
        // liffId = '1657043785-RVGnJDnw'
        break
    default:
        // liffId = '1657003171-5r419113'
        break
}
 */
async function main() {
    await liff.init({liffId: '1657043785-RVGnJDnw'})
    createApp(App).use(store).use(router).mount('#app')
    // liff.init({liffId: liffId})
    //     .then(()=>{
    //         createApp(App).use(store).use(router).mount('#app')
    //     })
    //     .catch((err)=>{
    //         document.write('access error : ' + err)
    //     })
}
main()